
import { Component, Vue, namespace } from 'nuxt-property-decorator'
import Mutate from '~/queries/switchSeller.gql'
import { emitError } from '~/utils/nuxt-helper'
import { SwitchSellerMutation, SwitchSellerMutationVariables, UserQuery, UserQueryVariables } from '~/types/eldamar'
import userQuery from '~/queries/user.gql'
import CompareArrowIcon from '~/components/element/icon/compare-arrow.vue'
import HoverMenu from '~/components/layout/menu/hover-menu.vue'

const sellerStore = namespace('seller')

interface User {
  id: string
  sellerId?: string
  name?: string
  team?: string
  color: string
}

@Component({
  components: {
    CompareArrowIcon,
    HoverMenu,
  },
})
export default class extends Vue {
  @sellerStore.State
  private readonly sellerId!: string

  @sellerStore.Action
  private readonly set!: () => Promise<boolean>

  @sellerStore.Action
  private readonly getDefaultPage!: () => Promise<string>

  private sellerUsers: User[] = []

  private async fetch (): Promise<void> {
    try {
      const result = await this.$apolloProvider?.defaultClient.query<UserQuery, UserQueryVariables>({
        query: userQuery,
      })
      if (!result) {
        throw new Error('apolloProvider is missing')
      }
      if (result.errors) {
        throw result.errors
      }
      const u = result.data.user
      if (!u || !u.sellerUsers) {
        throw new Error('user is missing')
      }
      this.sellerUsers = u.sellerUsers.map((s) => {
        const color = s.seller?.team?.color ? s.seller.team.color : '0'
        return {
          id: s.id,
          sellerId: s.seller?.id,
          name: s.seller?.office?.name,
          team: s.seller?.team?.name ? s.seller.team.name : '',
          color: `border-l-team-${color}`,
        }
      })
    } catch (e) {
      emitError(this.$nuxt, e)
    }
  }

  get isShown (): boolean {
    return !this.$fetchState.pending && this.sellerUsers.length > 1
  }

  get otherSellerUsers (): User[] {
    return this.sellerUsers.filter(su => su.sellerId !== this.sellerId)
  }

  private getUserColor (user: User, i: number): string {
    const classes: string[] = []
    if (i !== 0) {
      classes.push('border-t', 'border-white-alpha-10')
    }
    classes.push(user.color)
    return classes.join(' ')
  }

  private async select (userId: string): Promise<void> {
    try {
      if (!userId) {
        return
      }
      const res = await this.$apollo.mutate<SwitchSellerMutation, SwitchSellerMutationVariables>({
        mutation: Mutate,
        variables: {
          id: userId,
        },
      })
      if (!!res.data && !!res.data.switchSeller) {
        await this.set()
      }
    } catch (e) {
      emitError(this.$nuxt, e)
    }

    try {
      const target = await this.getDefaultPage()
      if (this.$route.fullPath === target) {
        location.reload()
        return
      }
      this.checkBank(target)
    } catch (e) {
      emitError(this.$nuxt, e)
    }
  }

  private async checkBank (target: string): Promise<void> {
    try {
      await this.$store.dispatch('bank-account/reset')
      const ok = await this.$store.dispatch('bank-account/check')
      if (!ok) {
        location.reload()
        return
      }
      location.href = target
    } catch (e) {
      emitError(this.$nuxt, e)
    }
  }
}
