// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/union.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".item[data-v-3e78344c]{align-items:center;display:flex;padding-bottom:.75rem;padding-top:.75rem}.item[data-v-3e78344c]:hover{background-color:rgba(0,0,0,.15)}.item-icon[data-v-3e78344c]{display:inline-block;width:2.5rem}hr[data-v-3e78344c]{border:none;border-top:1px solid;border-color:hsla(0,0%,100%,.1)}.logo-block[data-v-3e78344c]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");height:112px}.logo-space[data-v-3e78344c]{margin-top:128px}.collapsed-wrapper[data-v-3e78344c]{width:68px}.collapsed[data-v-3e78344c]{width:48px}.scroll-hidden[data-v-3e78344c]{-ms-overflow-style:none;scrollbar-width:none}.scroll-hidden[data-v-3e78344c]::-webkit-scrollbar{display:none}.h-full-with-alert[data-v-3e78344c]{height:calc(100% - 3rem)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
